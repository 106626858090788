<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>

    <b-container>

        <b-breadcrumb>
            <b-breadcrumb-item>
                <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon> Dashboard
            </b-breadcrumb-item>
        </b-breadcrumb>

        <b-row class="mb-5" align-v="stretch">
            <b-col v-if="isIntentoAdmin">
                <b-card title="Users Management" body-class="d-flex flex-column">
                    <b-card-text>Create, modify and delete Intento users.</b-card-text>
                    <b-button class="mt-auto" variant="primary" :to="{ name: 'userDashboard' }">Manage Intento Users</b-button>
                </b-card>
            </b-col>

            <b-col v-if="isIntentoSoftware">
                <b-card title="Software Management" body-class="d-flex flex-column">
                    <b-card-text>Create, edit and delete Intento Pro firmware updates.</b-card-text>
                    <b-button class="mt-auto" variant="primary" :to="{ name: 'softwareDashboard' }">Manage Software</b-button>
                </b-card>
            </b-col>

        </b-row>

        <b-row class="mb-5" align-v="stretch">

            <b-col v-if="isIntentoSales">
                <b-card title="Customer Management" body-class="d-flex flex-column">
                    <b-card-text>Manage Customers, Sites, Licenses, Billing...</b-card-text>
                    <b-button class="mt-auto" variant="primary" :to="{ name: 'customerDashboard' }">Manage sales</b-button>
                </b-card>
            </b-col>

            <b-col v-if="isIntentoSales">
                <b-card title="Inventory Management" body-class="d-flex flex-column">
                    <b-card-text>Associate seen devices with a serial number.</b-card-text>
                    <b-button class="mt-auto" variant="primary" :to="{ name: 'inventory' }">Manage Inventory</b-button>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="mb-5" align-v="stretch">
            <b-col v-if="isIntentoDPO">
                <b-card title="DPO Management" body-class="d-flex flex-column">
                    <b-card-text>Manage privacy policies and Audit logs.</b-card-text>
                    <b-button class="mt-auto" variant="primary" :to="{ name: 'dpoDashboard' }">DPO Management</b-button>
                </b-card>
            </b-col>
        </b-row>

    </b-container>

</template>

<script>

    import { mapGetters } from 'vuex';

    export default {
        name: "IntentoDashboardView",
        computed: {
            ...mapGetters('auth', ['isIntentoAdmin', 'isIntentoSales', 'isIntentoSoftware', 'isIntentoDPO'])
        }
    }

</script>

<style scoped>

</style>